import React, { ReactNode } from 'react';

interface IndexTableCellDefaultProps {
  tdClassName?: string;
  divClassName?: string;
  spanClassName?: string;
  withSpan?: boolean;
}

interface IndexTableCellWithTextProps {
  text: string;
  children?: never;
}

interface IndexTableCellWithChildrenProps {
  text?: never;
  children: ReactNode;
}

type IndexTableCellProps = IndexTableCellDefaultProps &
  (IndexTableCellWithTextProps | IndexTableCellWithChildrenProps);

function IndexTableCell({
  tdClassName,
  divClassName,
  spanClassName,
  withSpan = false,
  text,
  children
}: IndexTableCellProps) {
  if (withSpan) {
    return (
      <td className={tdClassName || 'px-3 py-2'}>
        <div className={divClassName || 'flex flex-wrap'}>
          <span
            className={
              spanClassName ||
              'inline-flex items-center px-2 py-0.5 m-0.5 rounded text-xs font-medium dark:bg-opacity-50 bg-gray-100 text-gray-800 dark:text-gray-100 dark:bg-gray-700'
            }
          >
            {text || children}
          </span>
        </div>
      </td>
    );
  }

  return (
    <td className={tdClassName || 'px-3 py-2'}>
      <div
        className={
          divClassName ||
          'text-gray-900 dark:text-gray-200 whitespace-nowrap font-normal text-sm'
        }
      >
        {text || children}
      </div>
    </td>
  );
}

export default IndexTableCell;

import React, { Fragment, ReactNode } from 'react';

import { SHOW_DEBUG } from '../../../../../config';

import {
  CheckedAll,
  OnCheckAll
} from '../../../../../common/hooks/useTableCheckable';

import { IndexTableHeaderSortableCell } from '../IndexTableHeaderSortableCell';
import { IndexTableHeaderCell } from '../IndexTableHeaderCell';

import { Checkbox } from '../../../../../helpers/Checkbox';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../helpers/Translate';
import { useFixedThead } from '../../../../../utils/useFixedThead';

import { formsFields, words } from '../../../../../locales/keys';

import { AdminPermissions } from '../../../../admin/adminConstants';

import {
  FetchItemsSort,
  FetchItemsSortableField,
  FetchItemsSortItems
} from '../../../../../types';

interface IndexTableHeaderDefaultProps {
  showable?: boolean;
  editable?: boolean;
  withoutCheckboxes?: boolean;
  withoutFixedThead?: boolean;
  children: ReactNode;
}

interface IndexTableHeaderWithSortProps {
  currentSort: FetchItemsSort;
  sortItems: FetchItemsSortItems;
  sortableField: FetchItemsSortableField;
}

interface IndexTableHeaderWithoutSortProps {
  currentSort?: never;
  sortItems?: never;
  sortableField?: never;
}

interface IndexTableHeaderWithCheckedProps {
  checkedAll: CheckedAll;
  onCheckAll: OnCheckAll;
}

interface IndexTableHeaderWithoutCheckedProps {
  checkedAll?: never;
  onCheckAll?: never;
}

type IndexTableHeaderProps = IndexTableHeaderDefaultProps &
  (IndexTableHeaderWithCheckedProps | IndexTableHeaderWithoutCheckedProps) &
  (IndexTableHeaderWithSortProps | IndexTableHeaderWithoutSortProps);

function IndexTableHeader({
  checkedAll = false,
  withoutCheckboxes = false,
  withoutFixedThead = false,
  onCheckAll,
  children,
  currentSort,
  showable,
  sortItems,
  sortableField,
  editable
}: IndexTableHeaderProps) {
  const { theadCallbackRef } = useFixedThead();

  return (
    <thead
      className="bg-gray-50 dark:bg-gray-850 z-20"
      ref={withoutFixedThead ? null : theadCallbackRef}
    >
      <tr>
        {onCheckAll && !withoutCheckboxes ? (
          <th
            scope="col"
            className="bg-gray-50 dark:bg-gray-850 dark:bg-opacity-90 font-medium left-0 bg-opacity-90 px-6 py-3 sticky text-center text-gray-500 text-xs tracking-wider uppercase z-20 dark:border-gray-700 border-gray-200 border-b"
          >
            <div className="flex items-center justify-center absolute inset-0">
              <Checkbox
                checkboxClassName="basic-checkbox border-2 bg-transparent dark:bg-transparent border-gray-400 dark:border-gray-600 group-hover:border-gray-600 dark:group-hover:border-gray-400"
                checked={checkedAll}
                id="check_all_permissions"
                onChange={onCheckAll}
              />
            </div>
          </th>
        ) : null}

        {children}

        {SHOW_DEBUG ? (
          <Fragment>
            <CheckPermissions action={AdminPermissions.ADMIN_READ_ANY_ID}>
              {sortItems ? (
                <IndexTableHeaderSortableCell
                  i18nText={formsFields.id}
                  sortableField={sortableField}
                  currentSort={currentSort}
                  onSort={sortItems}
                />
              ) : (
                <IndexTableHeaderCell i18nText={formsFields.id} />
              )}
            </CheckPermissions>
            <CheckPermissions action={AdminPermissions.ADMIN_READ_ANY_UUID}>
              <IndexTableHeaderCell i18nText={formsFields.uuid} />
            </CheckPermissions>
          </Fragment>
        ) : null}
        {showable ? (
          <th
            scope="col"
            className="px-6 py-3 dark:border-gray-700 border-gray-200 border-b"
          >
            <span className="sr-only">
              <Translate id={words.show} />
            </span>
          </th>
        ) : null}
        {editable ? (
          <th
            scope="col"
            className="px-6 py-3 dark:border-gray-700 border-gray-200 border-b"
          >
            <span className="sr-only">
              <Translate id={words.edit} />
            </span>
          </th>
        ) : null}
      </tr>
    </thead>
  );
}

export default IndexTableHeader;

import React, { useCallback } from 'react';

import { ID } from '../../../../../types';

import { Checkbox } from '../../../../../helpers/Checkbox';

interface IndexTableCheckboxProps {
  scope: string;
  itemId: ID;
  checked: boolean;
  onCheck: (value: ID) => void;
}

function IndexTableCheckbox({
  scope,
  itemId,
  checked,
  onCheck
}: IndexTableCheckboxProps) {
  const handleCheck = useCallback(() => onCheck(itemId), [itemId, onCheck]);

  return (
    <div className="flex items-center justify-center absolute inset-0">
      <Checkbox
        checkboxClassName="basic-checkbox border-2 bg-transparent dark:bg-transparent border-gray-400 dark:border-gray-600 group-hover:border-gray-600 dark:group-hover:border-gray-400"
        checked={checked}
        id={`check_${scope}_${itemId}`}
        onChange={handleCheck}
      />
    </div>
  );
}

export default IndexTableCheckbox;

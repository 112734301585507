import each from 'lodash/each';
import map from 'lodash/map';
import size from 'lodash/size';
import throttle from 'lodash/throttle';

import { findHorizontalScrollContainer } from './utils/findHorizontalScrollContainer';
import { findVerticalScrollContainer } from './utils/findVerticalScrollContainer';
import { isNeedFixedThead } from './utils/isNeedFixedThead';

class FixedThead {
  fixed = false;
  theadElem: HTMLElement;

  tableElem?: HTMLElement;
  verticalScrollContainerElem?: HTMLElement;
  horizontalScrollContainerElem?: HTMLElement;
  mockTheadElem?: HTMLElement;

  removeEventListenerCallbacks: { [key: string]: () => void } = {};

  constructor(theadElem: HTMLElement) {
    this.stop = this.stop.bind(this);

    this.handleVerticalScroll = throttle(
      this.handleVerticalScroll.bind(this),
      50
    );
    this.handleWindowResize = throttle(this.handleWindowResize.bind(this), 300);
    this.handleTableResize = throttle(this.handleTableResize.bind(this), 300);
    this.handleGlobalScroll = throttle(this.handleGlobalScroll.bind(this), 300);
    this.handleTheadMutation = throttle(
      this.handleTheadMutation.bind(this),
      100
    );
    this.handleHorizontalScroll = this.handleHorizontalScroll.bind(this);

    this.theadElem = theadElem;

    this.start();
  }

  static checkDependencies(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }
    if (!window.ResizeObserver) {
      console.log('ResizeObserver required');
      return false;
    }
    if (!window.MutationObserver) {
      console.log('MutationObserver required');
      return false;
    }

    return true;
  }

  handleHorizontalScroll(): void {
    this.updateTheadHorizontalScroll();
  }

  handleVerticalScroll(): void {
    this.updateFixed();
  }

  handleWindowResize(): void {
    this.updateContainers();
    this.updateStyles();
    this.updateTheadHorizontalScroll();
    this.updateFixed();
  }

  handleTableResize(): void {
    this.updateContainers();
    this.updateStyles();
    this.updateTheadHorizontalScroll();
    this.updateFixed();
  }

  handleTheadMutation(): void {
    this.updateMockTheadCellsAmount();
  }

  handleGlobalScroll(): void {
    this.updateContainers();
    this.updateFixed();
  }

  updateRemoveEventListenerCallback(eventName: string, cb: () => void) {
    if (this.removeEventListenerCallbacks[eventName]) {
      this.removeEventListenerCallbacks[eventName]();
    }
    this.removeEventListenerCallbacks[eventName] = cb;
  }

  removeEventListeners() {
    each(this.removeEventListenerCallbacks, (cb) => cb && cb());
    this.removeEventListenerCallbacks = {};
  }

  createMockThead() {
    this.mockTheadElem = this.theadElem.cloneNode(true) as HTMLElement;
    this.mockTheadElem.setAttribute('aria-hidden', 'true');
    this.updateMockTheadStyles();
    this.theadElem.before(this.mockTheadElem);
  }

  removeMockThead() {
    if (this.mockTheadElem) {
      this.mockTheadElem.remove();
      this.mockTheadElem = undefined;
    }
  }

  updateMockTheadCellsAmount() {
    if (!this.mockTheadElem) {
      return;
    }

    const thElements = this.theadElem.getElementsByTagName('th');
    const mockThElements = this.mockTheadElem.getElementsByTagName('th');

    if (size(thElements) !== size(mockThElements)) {
      this.restart();
      return;
    }
  }

  updateVerticalScrollContainer() {
    const container = findVerticalScrollContainer(this.theadElem);

    if (!container || container === this.verticalScrollContainerElem) {
      return;
    }

    container.addEventListener('scroll', this.handleVerticalScroll);

    this.updateRemoveEventListenerCallback('verticalScroll', () =>
      container.removeEventListener('scroll', this.handleVerticalScroll)
    );

    this.verticalScrollContainerElem = container;
  }

  updateHorizontalScrollContainer() {
    const container = findHorizontalScrollContainer(this.theadElem);

    if (!container || container === this.horizontalScrollContainerElem) {
      return;
    }

    container.addEventListener('scroll', this.handleHorizontalScroll);

    this.updateRemoveEventListenerCallback('horizontalScroll', () =>
      container.removeEventListener('scroll', this.handleHorizontalScroll)
    );

    this.horizontalScrollContainerElem = container;
  }

  updateTable() {
    const tableElem = this.theadElem.closest('table');

    if (!tableElem || tableElem === this.tableElem) {
      return;
    }

    this.tableElem = tableElem;
    const tableResizeObserver = new ResizeObserver(this.handleTableResize);
    tableResizeObserver.observe(this.tableElem);

    this.updateRemoveEventListenerCallback('tableResize', () =>
      tableResizeObserver.disconnect()
    );
  }

  updateContainers(): void {
    this.updateVerticalScrollContainer();
    this.updateHorizontalScrollContainer();
    this.updateTable();
  }

  updateTheadCellsStyles() {
    const thElements = this.theadElem.getElementsByTagName('th');

    if (this.fixed && this.mockTheadElem) {
      const mockThSizes = map(
        this.mockTheadElem.getElementsByTagName('th'),
        (thElem) => thElem.clientWidth
      );
      each(thElements, (thElem, index) => {
        const width = `${mockThSizes[index]}px`;
        thElem.style.minWidth = width;
        thElem.style.maxWidth = width;
      });
    } else {
      each(thElements, (thElem) => {
        thElem.style.minWidth = '';
        thElem.style.maxWidth = '';
      });
    }
  }

  updateTheadStyles() {
    if (this.fixed) {
      this.theadElem.style.position = 'fixed';
      this.theadElem.style.overflow = 'hidden';

      if (this.verticalScrollContainerElem) {
        const topOffset =
          this.verticalScrollContainerElem.getBoundingClientRect().top;
        this.theadElem.style.top = `${topOffset}px`;
      } else {
        this.theadElem.style.top = '';
      }

      if (this.horizontalScrollContainerElem) {
        const containerWidth = this.horizontalScrollContainerElem.clientWidth;
        this.theadElem.style.width = `${containerWidth}px`;
      } else {
        this.theadElem.style.width = '';
      }
    } else {
      this.theadElem.style.position = '';
      this.theadElem.style.overflow = '';
      this.theadElem.style.top = '';
      this.theadElem.style.width = '';
    }
  }

  updateMockTheadStyles() {
    if (this.mockTheadElem) {
      if (this.fixed) {
        this.mockTheadElem.style.display = '';
      } else {
        this.mockTheadElem.style.display = 'none';
      }
    }
  }

  updateStyles() {
    this.updateMockTheadStyles();
    this.updateTheadStyles();
    this.updateTheadCellsStyles();
  }

  updateTheadHorizontalScroll(): void {
    if (
      this.fixed &&
      this.horizontalScrollContainerElem &&
      this.horizontalScrollContainerElem.scrollLeft !==
        this.theadElem.scrollLeft
    ) {
      this.theadElem.scrollLeft = this.horizontalScrollContainerElem.scrollLeft;
    }
  }

  updateFixed(): void {
    const isNeed = isNeedFixedThead({
      theadElem: this.theadElem,
      tableElem: this.tableElem,
      verticalScrollContainerElem: this.verticalScrollContainerElem
    });

    if (isNeed && !this.fixed) {
      this.fixed = true;
      this.updateStyles();
      this.updateTheadHorizontalScroll();
    }

    if (!isNeed && this.fixed) {
      this.fixed = false;
      this.updateStyles();
    }
  }

  restart(): void {
    this.stop();
    this.start();
  }

  start(): void {
    if (!this.theadElem || !FixedThead.checkDependencies()) {
      return;
    }

    this.createMockThead();

    this.updateContainers();

    window.addEventListener('resize', this.handleWindowResize);
    this.updateRemoveEventListenerCallback('windowResize', () =>
      window.removeEventListener('resize', this.handleWindowResize)
    );

    window.addEventListener('scroll', this.handleGlobalScroll, true);
    this.updateRemoveEventListenerCallback('globalScroll', () =>
      window.removeEventListener('scroll', this.handleGlobalScroll, true)
    );

    const theadMutationObserver = new MutationObserver(
      this.handleTheadMutation
    );
    theadMutationObserver.observe(this.theadElem, {
      childList: true,
      subtree: true
    });

    this.updateRemoveEventListenerCallback('theadMutation', () =>
      theadMutationObserver.disconnect()
    );

    this.updateFixed();
  }

  stop(): void {
    this.removeEventListeners();

    this.fixed = false;
    this.updateStyles();

    this.tableElem = undefined;
    this.verticalScrollContainerElem = undefined;
    this.horizontalScrollContainerElem = undefined;
    this.removeMockThead();
  }
}

export default FixedThead;

import React, { memo } from 'react';

import { ID } from '../../../../../types';

import { OnSetCheckedIds } from '../../../../../common/hooks/useTableCheckable';

import { IndexTableCheckbox } from '../IndexTableCheckbox';
import { IndexTableStickyCell } from '../IndexTableStickyCell';

interface IndexTableRowCheckboxProps {
  scope: string;
  checked: boolean;
  onCheck: OnSetCheckedIds;
  itemId: ID;
}

function IndexTableRowCheckbox({
  scope,
  checked,
  itemId,
  onCheck
}: IndexTableRowCheckboxProps) {
  return (
    <IndexTableStickyCell isLeft>
      <IndexTableCheckbox
        scope={scope}
        checked={checked}
        itemId={itemId}
        onCheck={onCheck}
      />
    </IndexTableStickyCell>
  );
}

export default memo<IndexTableRowCheckboxProps>(IndexTableRowCheckbox);

import React from 'react';
import last from 'lodash/last';
import split from 'lodash/split';

import { UUID } from '../../../../../types';

import { IndexTableCell } from '../IndexTableCell';
import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';

import { copyToClipboard } from '../../../../../utils/copyToClipboard';

interface IndexTableCellUuidProps {
  uuid: UUID;
}

function IndexTableCellUuid({ uuid }: IndexTableCellUuidProps) {
  return (
    <IndexTableCell withSpan>
      <PureButtonHelper
        text={last(split(uuid, '-'))}
        onClick={() => copyToClipboard(uuid)}
      />
    </IndexTableCell>
  );
}

export default IndexTableCellUuid;

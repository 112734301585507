import { useCallback } from 'react';

import includes from 'lodash/includes';
import snakeCase from 'lodash/snakeCase';
import toUpper from 'lodash/toUpper';

import {
  FetchItemsSort,
  FetchItemsSortableField,
  FetchItemsSortItems
} from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

interface IndexTableHeaderSortableCellOptions {
  currentSort?: FetchItemsSort;
  onSort: FetchItemsSortItems;
  sortableField: FetchItemsSortableField;
}

function useIndexTableHeaderSortableCell({
  currentSort,
  onSort,
  sortableField
}: IndexTableHeaderSortableCellOptions) {
  const upCasedField = toUpper(snakeCase(sortableField));

  const ascField = `${upCasedField}_ASC`;

  const descField = `${upCasedField}_DESC`;

  const isSortedAsc = includes(currentSort, ascField);

  const isSortedDesc = includes(currentSort, descField);

  const sortIcon = isSortedDesc ? IconsEnum.SORT_DESC : IconsEnum.SORT_ASC;

  const handleSortItems = useCallback(() => {
    if (isSortedAsc) {
      return onSort?.([]);
    }
    if (isSortedDesc) {
      return onSort?.([ascField]);
    }

    return onSort?.([descField]);
  }, [isSortedAsc, isSortedDesc, onSort, ascField, descField]);

  return {
    isSortedDesc,
    isSortedAsc,
    sortIcon,
    handleSortItems
  };
}

export default useIndexTableHeaderSortableCell;

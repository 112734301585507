import React from 'react';
import cl from 'classnames';

import {
  I18nText,
  FetchItemsSort,
  FetchItemsSortableField,
  FetchItemsSortItems
} from '../../../../../types';

import { useIndexTableHeaderSortableCell } from './hooks/useIndexTableHeaderSortableCell';

import { Translate } from '../../../../../helpers/Translate';
import { Icon } from '../../../../../helpers/Icon';
import { ButtonHelper } from '../../../../../helpers/buttons/ButtonHelper';

interface IndexTableHeaderCellProps {
  currentSort?: FetchItemsSort;
  i18nText: I18nText;
  onSort: FetchItemsSortItems;
  sortableField: FetchItemsSortableField;
  textAlignEnd?: boolean;
}

function IndexTableHeaderSortableCell({
  currentSort,
  i18nText,
  onSort,
  sortableField,
  textAlignEnd = false
}: IndexTableHeaderCellProps) {
  const { handleSortItems, isSortedAsc, isSortedDesc, sortIcon } =
    useIndexTableHeaderSortableCell({
      currentSort,
      onSort,
      sortableField
    });

  return (
    <th
      scope="col"
      className="px-3 py-2 z-10 relative hover:bg-gray-100 dark:hover:bg-gray-800 dark:border-gray-700 border-gray-200 border-b"
    >
      <ButtonHelper
        className="group w-full rounded focus:ring-0 focus:ring-offset-0"
        onClick={handleSortItems}
      >
        <div
          className="absolute inset-0 hover:shadow dark:hover:glow z-5"
          aria-hidden="true"
        />
        <div
          className="absolute inset-0 border-2 border-current opacity-0 group-focus:opacity-100 rounded-md"
          aria-hidden="true"
        />
        <div
          className={cl('flex items-center justify-between text-gray-500', {
            'flex-row-reverse': textAlignEnd
          })}
        >
          <span className="text-xs font-medium uppercase tracking-wider whitespace-nowrap">
            <Translate id={i18nText} />
          </span>
          <div
            className={cl('ml-2 relative', {
              'opacity-0': !(isSortedAsc || isSortedDesc)
            })}
          >
            <Icon className="w-4 h-4" icon={sortIcon} />
          </div>
        </div>
      </ButtonHelper>
    </th>
  );
}

export default IndexTableHeaderSortableCell;

import React from 'react';
import cl from 'classnames';

import { I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { Translate } from '../../../../../helpers/Translate';
import { Icon } from '../../../../../helpers/Icon';

interface IndexTableHeaderCellProps {
  i18nText?: I18nText;
  icon?: IconsEnum | null;
  iconClassName?: string;
  textAlignEnd?: boolean;
}

function IndexTableHeaderCell({
  i18nText,
  icon,
  iconClassName,
  textAlignEnd = false
}: IndexTableHeaderCellProps) {
  return (
    <th
      scope="col"
      className="px-3 py-2 group focus-within:shadow dark:focus-within:glow focus-within:bg-gray-100 dark:focus-within:bg-gray-800 z-10 dark:border-gray-700 border-gray-200 border-b"
    >
      <div
        className={cl(
          'flex items-center',
          textAlignEnd ? 'justify-end' : 'justify-between'
        )}
      >
        <span className="text-xs leading-6 font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
          {icon ? <Icon className={iconClassName} icon={icon} /> : null}
          <Translate id={i18nText} />
        </span>
      </div>
    </th>
  );
}

export default IndexTableHeaderCell;

import { ReactNode, memo } from 'react';
import cl from 'classnames';

interface IndexTableStickyCellProps {
  isLeft?: boolean;
  isRight?: boolean;
  children: ReactNode;
}

function IndexTableStickyCell({
  isLeft,
  isRight,
  children
}: IndexTableStickyCellProps) {
  return (
    <td
      className={cl(
        'bg-white bg-opacity-90 dark:bg-gray-900 dark:bg-opacity-90 px-6 py-4 sticky text-center z-10 group-hover:bg-gray-100 dark:group-hover:bg-gray-850 group-hover:bg-opacity-80 dark:group-hover:bg-opacity-80',
        {
          'left-0': isLeft,
          'right-0': isRight
        }
      )}
    >
      {children}
    </td>
  );
}

export default memo<IndexTableStickyCellProps>(IndexTableStickyCell);

import React, { ReactNode, useCallback } from 'react';
import cl from 'classnames';

import { ClassName, ID, UUID } from '../../../../../types';

import { SHOW_DEBUG } from '../../../../../config';

import { OnSetCheckedIds } from '../../../../../common/hooks/useTableCheckable';

import { IndexTableRowCheckbox } from '../IndexTableRowCheckbox';
import { IndexTableRowEditButton } from '../IndexTableRowEditButton';
import { IndexTableRowExpandButton } from '../IndexTableRowExpandButton';
import { IndexTableCell } from '../IndexTableCell';
import { IndexTableCellUuid } from '../IndexTableCellUuid';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { AdminPermissions } from '../../../../admin/adminConstants';

interface IndexTableRowDefaultProps {
  expandable?: boolean;
  itemUuid?: UUID;
  children: ReactNode;
  className?: ClassName;
  withoutCheckbox?: boolean;
  selected?: boolean;
}

interface IndexTableRowWithCheckedProps {
  scope: string;
  checked?: boolean;
  itemId?: ID;
  onCheck?: OnSetCheckedIds;
}

interface IndexTableRowWithoutCheckedProps {
  scope?: never;
  checked?: never;
  itemId?: never;
  onCheck?: never;
}

interface IndexTableRowWithShowProps {
  onShow: (itemUuid: UUID) => void;
  onShowMouseOver?: (itemUuid: UUID) => void;
}

interface IndexTableRowWithoutShowProps {
  onShow?: never;
  onShowMouseOver?: never;
}

interface IndexTableRowWithEditProps {
  onEdit: (itemUuid: UUID) => void;
  onEditMouseOver?: (itemUuid: UUID) => void;
}

interface IndexTableRowWithoutEditProps {
  onEdit?: never;
  onEditMouseOver?: never;
}

type IndexTableRowProps = IndexTableRowDefaultProps &
  (IndexTableRowWithCheckedProps | IndexTableRowWithoutCheckedProps) &
  (IndexTableRowWithShowProps | IndexTableRowWithoutShowProps) &
  (IndexTableRowWithEditProps | IndexTableRowWithoutEditProps);

function IndexTableRow({
  scope,
  checked,
  expandable,
  itemId,
  itemUuid,
  className,
  onCheck,
  withoutCheckbox = false,
  onShow,
  onShowMouseOver,
  onEdit,
  onEditMouseOver,
  selected,
  children
}: IndexTableRowProps) {
  const handleShowButtonClick = useCallback(
    () => onShow?.(itemUuid),
    [itemUuid, onShow]
  );

  const handleShowButtonMouseOver = useCallback(
    () => onShowMouseOver?.(itemUuid),
    [itemUuid, onShowMouseOver]
  );

  const handleEditButtonClick = useCallback(
    () => onEdit?.(itemUuid),
    [itemUuid, onEdit]
  );

  const handleEditButtonMouseOver = useCallback(
    () => onEditMouseOver?.(itemUuid),
    [itemUuid, onEditMouseOver]
  );

  return (
    <tr
      className={
        className ||
        cl('hover:bg-gray-500 hover:bg-opacity-5 group', {
          'bg-gray-500 bg-opacity-10 dark:bg-opacity-20': selected
        })
      }
    >
      {onCheck && !withoutCheckbox ? (
        <IndexTableRowCheckbox
          scope={scope}
          checked={checked}
          itemId={itemId}
          onCheck={onCheck}
        />
      ) : null}

      {children}

      {SHOW_DEBUG ? (
        <>
          <CheckPermissions action={AdminPermissions.ADMIN_READ_ANY_ID}>
            {itemId ? <IndexTableCell text={itemId as string} /> : null}
          </CheckPermissions>
          <CheckPermissions action={AdminPermissions.ADMIN_READ_ANY_UUID}>
            {itemUuid ? <IndexTableCellUuid uuid={itemUuid} /> : null}
          </CheckPermissions>
        </>
      ) : null}
      {onShow ? (
        <IndexTableRowExpandButton
          onClick={handleShowButtonClick}
          onMouseEnter={handleShowButtonMouseOver}
        />
      ) : null}
      {onEdit && expandable ? (
        <IndexTableRowExpandButton
          onClick={handleEditButtonClick}
          onMouseEnter={handleEditButtonMouseOver}
        />
      ) : null}
      {onEdit && !expandable ? (
        <IndexTableRowEditButton
          onClick={handleEditButtonClick}
          onMouseEnter={handleEditButtonMouseOver}
        />
      ) : null}
    </tr>
  );
}

export default IndexTableRow;
